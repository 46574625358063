/*
 T1 - Ttiel1
 S1 - Subtitle1
*/

import React from 'react'
import { Typography } from '@material-ui/core'
import { s, globals } from '../style'

export const T = ({
  children,
  variant,
  extraCss,
  light,
  normal,
  bold,
  condensed,
  spacious,
  upper,
  lower,
  capital,
  left,
  right,
  center,
  d = 16, // desktop fontSize in pixels
  m = 14, // mobile fontSize in pixels
  t: td, // tablet fontSize in pixels (from md to lg)
  mb, // marginBottom ratio, 1 = fontSize
  mt, // marginTop ratio, 1 = fontSize
  xs,
  sm,
  md,
  lg,
  xl,
  o = 1, // opacity
  onScreen,
  className,
}) => {
  const t = td ? td : d
  return (
    <Typography
      className={className}
      variant={variant ? variant : 'body1'}
      css={[
        { fontFamily: 'font1' },
        { lineHeight: '1.15' },
        { transition: 'opacity 0.25s ease-out, transform 0.5s ease-out' },
        m && { [s.sm_down]: { fontSize: m / globals.typo.rem + 'rem' }}, // prettier-ignore
        t && { [s.sm]: { fontSize: t / globals.typo.rem + 'rem' }}, // prettier-ignore
        d && { [s.lg]: { fontSize: d / globals.typo.rem + 'rem' }}, // prettier-ignore
        xs && { [s.xs]: { fontSize: xs / globals.typo.rem + 'rem' }}, // prettier-ignore
        sm && { [s.sm_only]: { fontSize: sm / globals.typo.rem + 'rem' }}, // prettier-ignore
        md && { [s.md_only]: { fontSize: md / globals.typo.rem + 'rem' }}, // prettier-ignore
        lg && { [s.lg_only]: { fontSize: lg / globals.typo.rem + 'rem' }}, // prettier-ignore
        xl && { [s.xl]: { fontSize: xl / globals.typo.rem + 'rem' }}, // prettier-ignore
        condensed && m && { [s.sm_down]: { letterSpacing: (m / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        condensed && t && { [s.sm]: { letterSpacing: (t / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        condensed && d && { [s.lg]: { letterSpacing: (d / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        condensed && xs && { [s.xs]: { letterSpacing: (xs / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        condensed && sm && { [s.sm_only]: { letterSpacing: (sm / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        condensed && md && { [s.md_only]: { letterSpacing: (md / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        condensed && lg && { [s.lg_only]: { letterSpacing: (lg / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        spacious && xl && { [s.xl]: { letterSpacing: (xl / globals.typo.rem) * globals.typo.condensed + 'em' }}, // prettier-ignore
        spacious && m && { [s.sm_down]: { letterSpacing: (m / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && t && { [s.sm]: { letterSpacing: (t / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && d && { [s.lg]: { letterSpacing: (d / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && xs && { [s.xs]: { letterSpacing: (xs / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && sm && { [s.sm_only]: { letterSpacing: (sm / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && md && { [s.md_only]: { letterSpacing: (md / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && lg && { [s.lg_only]: { letterSpacing: (lg / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        spacious && xl && { [s.xl]: { letterSpacing: (xl / globals.typo.rem) * globals.typo.spacious + 'em' }}, // prettier-ignore
        mb && m && { [s.sm_down]: { marginBottom: (m / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && t && { [s.sm]: { marginBottom: (t / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && d && { [s.lg]: { marginBottom: (d / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && xs && { [s.xs]: { marginBottom: (xs / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && sm && { [s.sm_only]: { marginBottom: (sm / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && md && { [s.md_only]: { marginBottom: (md / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && lg && { [s.lg_only]: { marginBottom: (lg / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mb && xl && { [s.xl]: { marginBottom: (xl / globals.typo.rem) * mb + 'rem' }}, // prettier-ignore
        mt && m && { [s.sm_down]: { marginTop: (m / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && t && { [s.sm]: { marginTop: (t / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && d && { [s.lg]: { marginTop: (d / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && xs && { [s.xs]: { marginTop: (xs / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && sm && { [s.sm_only]: { marginTop: (sm / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && md && { [s.md_only]: { marginTop: (md / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && lg && { [s.lg_only]: { marginTop: (lg / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        mt && xl && { [s.xl]: { marginTop: (xl / globals.typo.rem) * mt + 'rem' }}, // prettier-ignore
        { lineHeight: d <= 20 ? '1.62em' : '1.15em' },
        { opacity: o },
        { fontWeight: 400 },
        light && { fontWeight: 300 },
        normal && { fontWeight: 400 },
        bold && { fontWeight: 700 },
        lower && { textTransform: 'lowercase' },
        upper && { textTransform: 'uppercase' },
        capital && { textTransform: 'capitalize' },
        left && { textAlign: 'left' },
        right && { textAlign: 'right' },
        center && {
          textAlign: 'center',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        onScreen === false && {
          opacity: 0,
          transform: 'translateX(-60px)',
        },
        extraCss,
      ]}>
      {children}
    </Typography>
  )
}

// export const P = ({ children, ...props }) => (
//   <T o={0.6} normal {...props}>
//     {children}
//   </T>
// )
