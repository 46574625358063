import React, { useState, useEffect, useCallback } from 'react'
import { s, colors, alpha } from '../../style'
import { ButtonReactive } from '../../buttons'
import FormBackdrop from '../FormItems/FormBackdrop'
import { Grid, TextField } from '@material-ui/core'
import { sTextInput } from '../FormItems/FormInputs'
import FormAgreements from '../FormItems/FormAgreements'
import { validateEmail } from '../../hooks/utils'
import axios from 'axios'

const form_success = {
  title: 'Wysłano',
  text:
    'Dziękujemy! Twoja wiadomość została wysłana. Oczekuj na odpowiedź drogą mailową lub telefonicznie',
}

const form_failure = {
  text:
    'Wygląda na to, że Twoja wiadomość nie została wysłana. Prosimy skontaktować się z nami mailowo',
  title: 'Niepowodzenie',
}

const form_button = {
  send: 'Wyślij!',
  fill: 'Wypełnij pola!',
}

const form_agreements = [
  {
    text:
      'Wysyłając formularz zgłoszeniowy potwierdzasz zgodę na przetwarzanie danych osobowych przez ReklamaDlaBiznesu.pl Sp. z o.o. oraz przesyłanie informacji handlowych za pomocą środków komunikacji elektronicznej na podany adres e-mail',
  },
]

const FormIntro = ({ theme }) => {
  const initialFormData = {
    name: '',
    email: '',
    phone: '',
    message: '',
  }

  const initialFormAgreements = form_agreements.map((e) => false)
  const [formData, setFormData] = useState(initialFormData)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)

  const agreementFilled =
    formAgreements.filter((value) => value).length === formAgreements.length

  const formDataEmail = formData.email

  const validate = useCallback(() => {
    return agreementFilled && validateEmail(formDataEmail)
  }, [agreementFilled, formDataEmail])

  const handleChangeForm = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const sendMail = async () => {
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:4000/sendmail'
            : 'https://cleverserver.appclever.pl/sendmail',
        data: {
          ...formData,
          selectedOptions: ['INTRO'],
          toEmail: 'serverSide',
          template: 'ReklamaKontakt',
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setOpenBackdrop(true)
            setSuccess(true)
            setFormData(initialFormData)
            setFormAgreements(initialFormAgreements)
            // console.log('TRUE')
            // console.log(res)
          } else {
            setOpenBackdrop(true)
            setSuccess(false)
            // console.log('FALSE')
            // console.log(res)
          }
        })
        .catch((error) => {
          setOpenBackdrop(true)
          setSuccess(false)
          // console.log('ERROR')
          // console.log(error)
        })
    }
  }

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  return (
    <div css={sFormContainer}>
      <div css={sForm}>
        <Grid container>
          <Grid item xs={12} css={sInputGrid}>
            <TextField
              onChange={handleChangeForm}
              name="name"
              value={formData.name}
              label={'Nadawca'}
              variant="filled"
              type="text"
              css={sTextInput(theme)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} css={sInputGrid}>
            <TextField
              onChange={handleChangeForm}
              name="email"
              value={formData.email}
              label={'Email'}
              variant="filled"
              type="email"
              css={[
                sTextInput(theme),
                formDataEmail !== '' &&
                  !validateEmail(formDataEmail) && {
                    'label, input': { color: 'red !important' },
                  },
              ]}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} css={sInputGrid}>
            <TextField
              onChange={handleChangeForm}
              name="phone"
              value={formData.phone}
              label={'Telefon'}
              variant="filled"
              type="tel"
              css={sTextInput(theme)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} css={sInputGrid}>
            <TextField
              onChange={handleChangeForm}
              name="message"
              value={formData.message}
              label={'Wiadomość'}
              variant="filled"
              type="text"
              css={sTextInput(theme)}
              fullWidth
            />
          </Grid>
        </Grid>
        <FormAgreements
          // isExpanded={false}
          hideWhiteHidder
          theme={theme}
          data={form_agreements}
          formAgreements={formAgreements}
          setFormAgreements={setFormAgreements}
          extraCssContainer={{
            [s.md]: { marginTop: 0, marginBottom: '1rem' },
          }}
        />
        <ButtonReactive
          onClickFunction={sendMail}
          active={mailState === 'filled'}
          theme={theme}
          center>
          {mailState === 'unfilled'
            ? form_button.fill
            : mailState === 'filled'
            ? form_button.send
            : form_button.send}
        </ButtonReactive>
        <FormBackdrop
          success={success}
          theme={theme}
          open={openBackdrop}
          setOpen={setOpenBackdrop}
          dataSuccess={form_success}
          dataFailure={form_failure}
        />
      </div>
    </div>
  )
}

const sFormContainer = {
  position: 'absolute',
  right: 0,
  top: 0,
  // backgroundColor: 'rgba(0,255,0,0.25)',
  height: '100%',
  [s.xs]: {
    padding: '4rem 0',
  },
  [s.ss]: {
    paddingTop: 0,
    paddingBottom: '4rem',
  },
  [s.md_down]: {
    width: '100vw',
    minHeight: 300,
    position: 'relative',
  },
  [s.sm]: { padding: '2rem' },
  [s.lg]: { width: '40%', paddingRight: '4rem' },

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
}

const sForm = {
  width: '100%',
  height: 'max-content',
  minHeight: 300,
  borderRadius: '2rem',
  background: `linear-gradient(to bottom right, ${colors.white.concat(
    alpha[90]
  )}, ${colors.white.concat(alpha[30])})`,
  padding: '2rem',
  [s.xs]: {
    padding: '1.5rem',
  },
}

const sInputGrid = {
  marginBottom: '1rem',
}

export default FormIntro
