import React from 'react'
import Img from 'gatsby-image'
import { globals, s, colors, alpha } from '../../style'
import { T } from '../../typo'
import { ButtonGradient } from '../../buttons'
import { useInView } from 'react-intersection-observer'
import FormIntro from '../../forms/FormIntro/FormIntro'

const Intro = ({ data, theme, maxWidth }) => {
  const {
    align,
    buttonGradient,
    descriptionObject,
    language,
    slug_section,
    subtitleObject,
    titleComplex,
    imageQuality,
  } = data

  const { ref, inView: onScreen } = useInView({
    threshold: 0,
  })

  return (
    <div css={{ position: 'relative', top: 0, left: 0, zIndex: 0 }}>
      <section css={[sSection]}>
        <div
          css={[
            sContainer,
            onScreen === false && {
              opacity: 0 /* contentVisibility: 'hidden' */,
            },
          ]}
          id={slug_section}
          ref={ref}>
          <div css={sBackground}>
            {imageQuality?.childImageSharp ? (
              <Img
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                fluid={imageQuality.childImageSharp.fluid}
                alt={imageQuality.name}
              />
            ) : (
              <div css={[sBackground, sBackgroundTheme(theme)]} />
            )}
            <div css={[sExtraGradient(theme)]} />
          </div>
          <div
            css={[
              sTextContainer,
              sAlign(align),
              maxWidth && { maxWidth: maxWidth },
            ]}>
            {titleComplex && (
              <T
                // m={48}
                // d={96}
                // sm={64}
                // md={64}
                // lg={72}
                m={38}
                d={48}
                sm={64}
                md={64}
                lg={48}
                variant={titleComplex.variant}
                onScreen={onScreen}
                extraCss={[
                  {
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    [s.ss]: { fontSize: '2.5rem' },
                    transition:
                      'opacity 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.25s, transform 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.25s',
                  },
                  align === 'center' && {
                    justifyContent: 'center',
                  },
                  align === 'right' && {
                    textAlign: 'right',
                    justifyContent: 'flex-end',
                  },
                  // onScreen === false && {
                  //   opacity: 0,
                  //   transform: 'translateZ(-1200px)',
                  // },
                ]}>
                {titleComplex.texts.map((text, id) => (
                  <span
                    key={id}
                    css={[
                      {
                        fontWeight: text.fontWeight,
                        // transition: `opacity 0.5s ease-out 0.25s, transform 0.5s ease-out ${
                        //   0.25 + 1 * id
                        // }s`,
                      },
                      text.uppercase && { textTransform: 'uppercase' },
                      text.gradient && text.gradient.colors.length > 1
                        ? {
                            background: `linear-gradient(${
                              text.gradient?.angle !== null &&
                              `${text.gradient.angle}deg,`
                            }${text.gradient?.colors.map((color, id) =>
                              id < text.gradient?.colors?.length - 1
                                ? color?.color && `${color.color}`
                                : color.color
                            )})`,
                            // ['-webkit-background-clip']: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            // ['-webkit-text-fill-color']: 'transparent',
                          }
                        : { color: text.gradient.colors[0].color },
                      // !onScreen && {
                      //   opacity: 0,
                      //   transform: 'translateZ(-1400px)',
                      // },
                    ]}>
                    {text.text}
                  </span>
                ))}
              </T>
            )}
            {subtitleObject && (
              <T
                d={32}
                t={32}
                m={24}
                upper={subtitleObject.uppercase}
                variant={subtitleObject.variant}
                extraCss={[
                  {
                    fontWeight: subtitleObject.fontWeight,
                    color: subtitleObject.color,
                    [s.sm_down]: {
                      paddingTop: '2rem',
                    },
                    [s.md]: {
                      paddingTop: '4rem',
                    },
                    transition:
                      'opacity 0.4s ease-out 0.5s, transform 0.4s ease-out 0.5s',
                  },
                  onScreen === false && {
                    opacity: 0,
                    transform: 'translateX(-120px)',
                  },
                ]}>
                {subtitleObject.text}
              </T>
            )}
            {descriptionObject && (
              <T
                d={16}
                m={14}
                center={align === 'center'}
                variant={descriptionObject.variant}
                extraCss={[
                  {
                    fontWeight: descriptionObject.fontWeight,
                    color: descriptionObject.color,
                    [s.sm_down]: {
                      width: '100%',
                      padding: '2rem 0 3rem',
                    },
                    [s.md]: {
                      width: '50%',
                      padding: '4rem 0',
                    },
                    transition:
                      'opacity 0.6s ease-out 0.75s, transform 0.4s ease-out 0.75s',
                  },
                  onScreen === false && {
                    opacity: 0,
                    transform: 'translateX(-120px)',
                  },
                ]}>
                {descriptionObject.text}
              </T>
            )}
            <div
              css={[
                onScreen === false && {
                  opacity: 0 /* contentVisibility: 'hidden' */,
                },
                { transition: 'opacity 0.4s ease-out 1s' },
              ]}>
              {buttonGradient && (
                <ButtonGradient
                  language={language}
                  button={buttonGradient}
                  align={align}>
                  {buttonGradient.text}
                </ButtonGradient>
              )}
            </div>
          </div>
        </div>
      </section>
      <FormIntro theme={theme} />
    </div>
  )
}

const sSection = {
  [s.sm_down]: { height: `calc(100vh - ${globals.menu.height.mobile}px)` },
  [s.md]: { height: `calc(100vh - ${globals.menu.height.desktop}px)` },
  minHeight: 700,
}

const sContainer = {
  transition: 'opacity 1s ease-out',
  position: 'relative',
  width: '100vw',
  height: '100vh',
  minHeight: 700,
  [s.sm_down]: {
    top: -globals.menu.height.mobile,
  },
  [s.md]: {
    top: -globals.menu.height.desktop,
  },
}

const sBackground = {
  zIndex: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
}
const sExtraGradient = (theme) => ({
  zIndex: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: `linear-gradient(${colors[theme].background[0].concat(
    alpha[60]
  )}, ${colors[theme].background[0].concat(alpha[0])})`,
})

const sBackgroundTheme = (theme) => ({
  background: `linear-gradient(175deg, ${colors[
    theme
  ].menuFullScreen.background[0].concat(alpha[30])}, ${
    colors[theme].menuFullScreen.background[1]
  })`,
})

const sTextContainer = {
  position: 'relative',
  top: 32,
  left: 0,
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: '2rem',
  [s.ss]: {
    padding: '1rem',
  },
  height: '100%',
  maxWidth: globals.maxWidth,
  width: '100%',
  margin: '0 auto',
}

const sAlign = (align) => {
  if (align === 'center')
    return {
      textAlign: 'center',

      alignItems: 'center',
    }
  if (align === 'left')
    return {
      textAlign: 'left',
      alignItems: 'flex-start',
    }
  if (align === 'right')
    return {
      textAlign: 'right',
      alignItems: 'flex-end',
    }
}

export default Intro
